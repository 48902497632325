import * as React from "react";

const Menu = () => {
  const [open, setOpen] = React.useState(false);

  // urls are all lower case to match Links component and Contact component ids
  const links = [
    {
      title: "Inter-Quest",
      url: "https://www.wemaketechsimple.com",
    },
    {
      title: "Contact Us",
      url: "https://www.wemaketechsimple.com/contact",
    },
  ];

  return (
    <>
      <button
        type="button"
        className="inline-flex items-center justify-center p-2 rounded-md text-white bg-primary hover:bg-primary-alt focus:outline-none focus:bg-cool-gray-300 focus:text-cool-gray-800 transition duration-150 ease-in-out"
        onClick={() => setOpen(!open)}
        aria-label="Open/Close Menu"
      >
        <span className={"antialiased font-bold mr-2"}>Menu</span>
        <svg
          className="h-6 w-6"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </button>
      <div
        className={`${
          open ? "absolute" : "hidden"
        } top-0 right-0 ml-auto p-2 transition transform origin-top-right w-64 max-w-lg`}
        style={{ zIndex: "9999999" }}
      >
        <div className="rounded-lg shadow-md">
          <div className="rounded-lg bg-primary-alt shadow-xs overflow-hidden">
            <div className="px-5 pt-4 flex items-center justify-end">
              <span
                className={"antialiased font-bold mr-auto text-white text-xl"}
              >
                Menu
              </span>
              <div className="-mr-2">
                <button
                  type="button"
                  className="inline-flex items-center justify-center p-2 rounded-md text-white hover:bg-primary focus:outline-none focus:bg-primary transition duration-150 ease-in-out"
                  onClick={() => setOpen(!open)}
                  aria-label="Open/Close Menu"
                >
                  <svg
                    className="h-6 w-6"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div className="px-2 pt-2 pb-3">
              {links.map((link, i) => (
                //   Wrapper for onClick to work (cannot attach onClick to Link)
                <div onClick={() => setOpen(!open)} key={i}>
                  <a
                    href={link.url}
                    className={`${
                      i > 0 ? "mt-1" : ""
                    } block px-3 py-2 rounded-md text-base font-medium text-white hover:text-primary hover:underline hover:bg-cool-gray-200 focus:outline-none focus:underline focus:bg-cool-gray-200 transition duration-150 ease-in-out`}
                  >
                    {link.title}
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Menu;
