import React from "react";
import { navigate, graphql, Link } from "gatsby";
import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";
// import { Link, graphql } from "gatsby";

import AniLink from "gatsby-plugin-transition-link/AniLink";

import Layout from "./Layout";
import SEO from "./Seo";

import { AppContext } from "../context/AppContext";
import Sidebar from "./Sidebar";
import IqLogo from "../images/iq-logo-wide-white.png";
import CiscoPng from "../images/cisco-partner-logo.png";
import DamChickenLogo from "../images/dam-chicken-logo.png";
import { css } from "@emotion/core";

import MenuButton from "./MenuButton";
import Headroom from "react-headroom";

const shortcodes = { Link: AniLink }; // Provide common components here

const MdxPageLayout = ({ data: { mdx, allMdx } }) => {
  const context = React.useContext(AppContext);

  const getPrevious = (i) => {
    if (i === null) return null;
    return allMdx.edges[i - 1];
  };
  const getNext = (i) => {
    if (i === null) return null;
    return allMdx.edges[i + 1];
  };

  const getIdxOfCurrent = () => {
    let idx = null;
    allMdx.edges.forEach((edge, i) => {
      if (edge.node.id === mdx.id) {
        idx = i;
      }
    });
    return idx;
  };

  const currentIdx = getIdxOfCurrent();

  const previous = getPrevious(currentIdx);
  const next = getNext(currentIdx);

  // if currentIdx is not 0 and completedSteps does not include the previous Idx, let's direct them back to the last completed idx/step
  // if (currentIdx !== 0 && !context.completedSteps.includes(currentIdx - 1)) {
  //   const lastCompletedStepIdx = context.completedSteps.length - 1;
  //   console.log("last completed step idx: ", lastCompletedStepIdx);
  //   if (lastCompletedStepIdx < 1) {
  //     return navigate(context.allSteps[0].node.fields.slug);
  //   } else {
  //     return navigate(context.allSteps[lastCompletedStepIdx].node.fields.slug);
  //   }
  // }

  React.useEffect(() => {
    if (currentIdx !== 0 && context.completedSteps.length === 0) {
      return navigate(context.allSteps[0].node.fields.slug);
    }
    context.setState((s) => ({ ...s, currentStep: currentIdx }));
    if (context.completedSteps.includes(currentIdx)) {
      return;
    }

    context.setState((s) => ({
      ...s,
      completedSteps: [...context.completedSteps, currentIdx].sort(),
    }));
  }, []);

  return (
    <>
      <main>
        <SEO title={mdx.frontmatter.heading} />
        <Headroom style={{ zIndex: "99999" }}>
          <div
            className={
              "relative h-14 bg-white flex justify-between items-center px-2 md:px-4 border-b border-cool-gray-200 shadow-sm"
            }
          >
            <a
              href={"https://www.wemaketechsimple.com"}
              className={"block max-h-full h-12 flex items-center"}
              style={{ maxWidth: "48vw" }}
            >
              <img
                src={
                  "https://www.wemaketechsimple.com/wp-content/uploads/2018/07/cropped-logo-1.png"
                }
                alt={"interquest logo"}
                className={"max-h-full w-auto"}
              />
            </a>
            <nav className={"space-x-6"}>
              <a
                href={"https://www.wemaketechsimple.com/contact"}
                className={"hidden md:inline-block font-bold hover:underline"}
              >
                Contact Us
              </a>
              <MenuButton />
            </nav>
          </div>
        </Headroom>
        <Sidebar />
        <div className="flex flex-wrap py-8 md:py-16">
          <div className="prose mx-auto p-4 w-full">
            <h1>{mdx.frontmatter.heading}</h1>
            <MDXProvider components={shortcodes}>
              <MDXRenderer>{mdx.body}</MDXRenderer>
            </MDXProvider>
            <div className={`w-full flex mt-8`}>
              <div className="w-1/2 flex justify-start">
                {previous && (
                  <div
                    className={
                      "border-b border-transparent hover:border-b hover:border-primary"
                    }
                  >
                    <Link
                      to={previous.node.fields.slug}
                      className="flex items-center justify-center font-extrabold text-primary text-2xl py-1 px-2"
                      style={{ textDecoration: "none" }}
                    >
                      <ChevronSvg lg flip />
                      Previous
                    </Link>
                  </div>
                )}
              </div>
              <div className="w-1/2 flex justify-end">
                {next && (
                  <div
                    className={
                      "border-b border-transparent hover:border-b hover:border-primary"
                    }
                  >
                    <Link
                      to={next.node.fields.slug}
                      className="flex items-center justify-center font-extrabold text-primary text-2xl py-1 px-2"
                      style={{ textDecoration: "none" }}
                    >
                      Next
                      <ChevronSvg lg />
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
      <footer className="bg-primary text-white">
        <div className="flex flex-wrap justify-center space-y-4 items-center py-8">
          <div className="flexw-full md:w-auto px-4 justify-center">
            <a href="https://www.wemaketechsimple.com">
              <img src={IqLogo} alt="Inter-Quest logo" />
            </a>
          </div>
          <div className="flex w-full md:w-auto px-4 justify-center">
            <img
              src={CiscoPng}
              alt="Cisco partner logo"
              className="h-24 w-auto"
            />
          </div>
          <div className="flex w-full md:w-auto px-4 justify-center">
            <a href="https://www.damchicken.com">
              <img
                src={DamChickenLogo}
                alt="Dam Chicken logo"
                className="h-24 w-auto"
              />
            </a>
          </div>
        </div>
      </footer>
    </>
  );
};

const ChevronSvg = ({ lg, sm, flip }: ChevonSvgProps) => (
  <svg
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    fill={"currentColor"}
    className={`${lg ? "w-6 h-6 md:w-10 md:h-10" : "w-4 h-4 md:w-7 md:h-7"}`}
    css={css`
      transform: ${flip ? "scale(-1)" : ""};
    `}
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fillRule="evenodd">
      <g id="icon-shape">
        <polygon
          id="Combined-Shape"
          points="12.9497475 10.7071068 13.6568542 10 8 4.34314575 6.58578644 5.75735931 10.8284271 10 6.58578644 14.2426407 8 15.6568542 12.9497475 10.7071068"
        />
      </g>
    </g>
  </svg>
);

export const pageQuery = graphql`
  query MdxFileQueryNew($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        order
        heading
      }
    }
    allMdx(
      filter: { frontmatter: { group: { eq: "study" } } }
      sort: { fields: frontmatter___order }
    ) {
      edges {
        node {
          frontmatter {
            order
            title
          }
          id
          fields {
            slug
          }
        }
      }
    }
  }
`;
export default MdxPageLayout;
