import * as React from "react";
import { AppContext } from "../context/AppContext";
import { graphql, Link, useStaticQuery } from "gatsby";
import LogoPng from "../images/iq-logo-wide-white.png";

const Sidebar = () => {
  const context = React.useContext(AppContext);
  const completedMdx = context.completedSteps.map(
    (completed) => context.allSteps[completed]
  );

  const data = useStaticQuery(graphql`
    query SidebarStudyPagesQueryNew {
      allMdx(
        filter: { frontmatter: { group: { eq: "study" } } }
        sort: { fields: frontmatter___order }
      ) {
        edges {
          node {
            frontmatter {
              order
              title
            }
            id
            fields {
              slug
            }
          }
        }
      }
    }
  `);

  return (
    <div className={"bg-primary p-4 md:p-8 antialiased relative"}>
      {/*<Menu />*/}
      <p
        className={"text-center text-white text-3xl md:text-5xl"}
        style={{ fontFamily: "'Berkshire Swash', cursive" }}
      >
        Case Study: Dam Chicken
      </p>
      <nav
        className={"md:sticky mt-2 md:mt-0 md:top-0"}
        // style={{ zIndex: "9999" }}
      >
        <ul className="w-full flex flex-wrap justify-center">
          {data.allMdx.edges.map((mdx, i, arr) => (
            <li
              key={mdx.node.id}
              className={`md:w-auto text-sm md:text-xl font-extrabold p-1 ${
                context?.completedSteps.includes(i)
                  ? "text-cool-gray-200"
                  : "text-cool-gray-600"
              }`}
            >
              {context?.completedSteps.includes(i) ? (
                <Link
                  to={mdx.node.fields.slug}
                  className={"flex items-center h-full"}
                >
                  <span
                    className={`${
                      context?.currentStep === i
                        ? "text-xl md:text-4xl text-white"
                        : ""
                    }`}
                  >
                    {mdx.node.frontmatter.title}
                  </span>
                  {arr.length - 1 === i ? null : (
                    <ChevronSvg
                      lg={context?.currentStep === i}
                      sm={context?.currentStep !== i}
                    />
                  )}
                </Link>
              ) : (
                <div className={"flex items-center h-full"}>
                  <span className={"text-xs md:text-lg"}>
                    {mdx.node.frontmatter.title}
                  </span>
                  {arr.length - 1 === i ? null : <ChevronSvg sm />}
                </div>
              )}
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

interface ChevonSvgProps {
  lg?: boolean;
  sm?: boolean;
}
const ChevronSvg = ({ lg, sm }: ChevonSvgProps) => (
  <svg
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    fill={"currentColor"}
    className={`${lg ? "h-4 md:h-10" : "h-4 md:h-7"}`}
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fillRule="evenodd">
      <g id="icon-shape">
        <polygon
          id="Combined-Shape"
          points="12.9497475 10.7071068 13.6568542 10 8 4.34314575 6.58578644 5.75735931 10.8284271 10 6.58578644 14.2426407 8 15.6568542 12.9497475 10.7071068"
        />
      </g>
    </g>
  </svg>
);

const Menu = () => {
  const [open, setOpen] = React.useState(false);

  // urls are all lower case to match Links component and Contact component ids
  const links = [
    {
      title: "Inter-Quest",
      url: "https://www.wemaketechsimple.com",
    },
    {
      title: "Contact Us",
      url: "https://www.wemaketechsimple.com/contact",
    },
  ];

  return (
    <>
      <button
        type="button"
        className="absolute top-4 right-5 inline-flex items-center justify-center p-2 rounded-md text-white hover:bg-primary-alt focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
        onClick={() => setOpen(!open)}
        aria-label="Open/Close Menu"
      >
        <span className={"antialiased font-bold mr-2"}>Menu</span>
        <svg
          className="h-6 w-6"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </button>
      <div
        className={`${
          open ? "absolute" : "hidden"
        } top-0 right-0 ml-auto inset-x-0 p-2 transition transform origin-top-right max-w-lg`}
        style={{ zIndex: "9999999" }}
      >
        <div className="rounded-lg shadow-md">
          <div className="rounded-lg bg-primary-alt shadow-xs overflow-hidden">
            <div className="px-5 pt-4 flex items-center justify-end">
              <span
                className={"antialiased font-bold mr-auto text-white text-xl"}
              >
                Menu
              </span>
              <div className="-mr-2">
                <button
                  type="button"
                  className="inline-flex items-center justify-center p-2 rounded-md text-white hover:bg-primary focus:outline-none focus:bg-primary transition duration-150 ease-in-out"
                  onClick={() => setOpen(!open)}
                  aria-label="Open/Close Menu"
                >
                  <svg
                    className="h-6 w-6"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div className="px-2 pt-2 pb-3">
              {links.map((link, i) => (
                //   Wrapper for onClick to work (cannot attach onClick to Link)
                <div onClick={() => setOpen(!open)} key={i}>
                  <a
                    href={link.url}
                    className={`${
                      i > 0 ? "mt-1" : ""
                    } block px-3 py-2 rounded-md text-base font-medium text-white hover:text-primary hover:underline hover:bg-cool-gray-200 focus:outline-none focus:underline focus:bg-cool-gray-200 transition duration-150 ease-in-out`}
                  >
                    {link.title}
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
